$gutter: .5rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";



.title-center {
  text-align: center;
  font-size: 25px !important;
  font-weight: 500 !important;
}

.title-center-menu {
  text-align: center;
  font-size: 25px !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalized {
  text-transform: lowercase;
}

.capitalized::first-letter {
  text-transform: uppercase;
}

.m-0 {
  margin: 0px;
}

.saldo-label{
  color: #3b7e0e;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.saldo-label-blue{
  color: #0e70ff;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.saldo-label-red{
  color: #eb0117;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.saldo-label-fp{
  color: #000000;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.saldo-monto{
  margin-bottom: 0.3rem;
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.monto-fp{
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  font-size: 13px;
}

.mb-1{
  margin-bottom: 1rem;
}

.mtb-1{
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}


.label {
  border-radius: 0;
  text-shadow: none;
  font-size: 11px;
  font-weight: normal;
  padding: 3px 5px 3px;
  background-color: #abbac3 !important;
  color: #fff !important;
}

.label[class*="span"][class*="arrow"] {
  min-height: 0
}

.badge {
  text-shadow: none;
  font-size: 12px;
  padding: 1px 15px;
  font-weight: normal;
  line-height: 15px;
  background-color: #ABBAC3 !important;
  color: #fff !important;
}

.label-transparent,
.badge-transparent {
  background-color: transparent !important
}

.label-grey,
.badge-grey {
  background-color: #a0a0a0 !important
}

.label-info,
.badge-info {
  background-color: #3a87ad !important
}

.label-primary,
.badge-primary {
  background-color: #2283c5 !important
}

.label-success,
.badge-success {
  background-color: #82af6f !important
}

.label-important,
.badge-important {
  background-color: #d15b47 !important
}

.label-inverse,
.badge-inverse {
  background-color: #333 !important
}

.label-warning,
.badge-warning {
  background-color: #f89406 !important
}

.label-pink,
.badge-pink {
  background-color: #d6487e !important
}

.label-purple,
.badge-purple {
  background-color: #9585bf !important
}

.label-yellow,
.badge-yellow {
  background-color: #fee188 !important
}

.label-light,
.badge-light {
  background-color: #e7e7e7 !important
}

.badge-yellow,
.label-yellow {
  color: #963 !important;
  border-color: #fee188
}

.badge-light,
.label-light {
  color: #888 !important
}

.label.arrowed,
.label.arrowed-in {
  position: relative;
  margin-left: 9px
}

.label.arrowed:before,
.label.arrowed-in:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -14px;
  top: 0;
  border: 9px solid transparent;
  border-width: 9px 7px;
  border-right-color: #abbac3
}

.label.arrowed-in:before {
  border-color: #abbac3;
  border-left-color: transparent !important;
  left: -9px
}

.label.arrowed-right,
.label.arrowed-in-right {
  position: relative;
  margin-right: 9px
}

.label.arrowed-right:after,
.label.arrowed-in-right:after {
  display: inline-block;
  content: "";
  position: absolute;
  right: -14px;
  top: 0;
  border: 9px solid transparent;
  border-width: 9px 7px;
  border-left-color: #abbac3
}

.p-card .p-card-body {
  padding: 1rem;
}

.label.arrowed-in-right:after {
  border-color: #abbac3;
  border-right-color: transparent !important;
  right: -9px
}

.label-info.arrowed:before {
  border-right-color: #3a87ad
}

.label-info.arrowed-in:before {
  border-color: #3a87ad
}

.label-info.arrowed-right:after {
  border-left-color: #3a87ad
}

.label-info.arrowed-in-right:after {
  border-color: #3a87ad
}

.label-primary.arrowed:before {
  border-right-color: #2283c5
}

.label-primary.arrowed-in:before {
  border-color: #2283c5
}

.label-primary.arrowed-right:after {
  border-left-color: #2283c5
}

.label-primary.arrowed-in-right:after {
  border-color: #2283c5
}

.label-success.arrowed:before {
  border-right-color: #82af6f
}

.label-success.arrowed-in:before {
  border-color: #82af6f
}

.label-success.arrowed-right:after {
  border-left-color: #82af6f
}

.label-success.arrowed-in-right:after {
  border-color: #82af6f
}

.label-warning.arrowed:before {
  border-right-color: #f89406
}

.label-warning.arrowed-in:before {
  border-color: #f89406
}

.label-warning.arrowed-right:after {
  border-left-color: #f89406
}

.label-warning.arrowed-in-right:after {
  border-color: #f89406
}

.label-important.arrowed:before {
  border-right-color: #d15b47
}

.label-important.arrowed-in:before {
  border-color: #d15b47
}

.label-important.arrowed-right:after {
  border-left-color: #d15b47
}

.label-important.arrowed-in-right:after {
  border-color: #d15b47
}

.label-inverse.arrowed:before {
  border-right-color: #333
}

.label-inverse.arrowed-in:before {
  border-color: #333
}

.label-inverse.arrowed-right:after {
  border-left-color: #333
}

.label-inverse.arrowed-in-right:after {
  border-color: #333
}

.label-pink.arrowed:before {
  border-right-color: #d6487e
}

.label-pink.arrowed-in:before {
  border-color: #d6487e
}

.label-pink.arrowed-right:after {
  border-left-color: #d6487e
}

.label-pink.arrowed-in-right:after {
  border-color: #d6487e
}

.label-purple.arrowed:before {
  border-right-color: #9585bf
}

.label-purple.arrowed-in:before {
  border-color: #9585bf
}

.label-purple.arrowed-right:after {
  border-left-color: #9585bf
}

.label-purple.arrowed-in-right:after {
  border-color: #9585bf
}

.label-yellow.arrowed:before {
  border-right-color: #fee188
}

.label-yellow.arrowed-in:before {
  border-color: #fee188
}

.label-yellow.arrowed-right:after {
  border-left-color: #fee188
}

.label-yellow.arrowed-in-right:after {
  border-color: #fee188
}

.label-light.arrowed:before {
  border-right-color: #e7e7e7
}

.label-light.arrowed-in:before {
  border-color: #e7e7e7
}

.label-light.arrowed-right:after {
  border-left-color: #e7e7e7
}

.label-light.arrowed-in-right:after {
  border-color: #e7e7e7
}

.label-grey.arrowed:before {
  border-right-color: #a0a0a0
}

.label-grey.arrowed-in:before {
  border-color: #a0a0a0
}

.label-grey.arrowed-right:after {
  border-left-color: #a0a0a0
}

.label-grey.arrowed-in-right:after {
  border-color: #a0a0a0
}

.label-large {
  font-size: 13px;
  padding: 3px 8px 4px
}

.label-large.arrowed,
.label-large.arrowed-in {
  margin-left: 12px
}

.label-large.arrowed:before,
.label-large.arrowed-in:before {
  left: -16px;
  border-width: 11px 8px
}

.label-large.arrowed-in:before {
  left: -12px
}

.label-large.arrowed-right,
.label-large.arrowed-in-right {
  margin-right: 11px
}

.label-large.arrowed-right:after,
.label-large.arrowed-in-right:after {
  right: -16px;
  border-width: 11px 8px
}

.label-large.arrowed-in-right:after {
  right: -12px
}

.label-form {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  display:inline-block;
  margin-top: 0;
  font-weight: normal;
  font-size: 0.89em;
}


.text-bold {
  font-weight: bold;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.75rem;
}

h5 {
  font-size: 0.5rem;
}

h6 {
  font-size: 0.25rem;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.5rem 0.75rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 1rem;
}

.p-card .p-card-content {
  padding: 0 0;
}

.p-fieldset .p-fieldset-content {
  padding: 0.75rem;
}

.space-buttons > * {
  margin-left: 3px;
  margin-right: 3px;
}

.p-panel .p-panel-header {
  padding: 0.75rem 1rem;
}

@media (min-width: 1025px){
  .layout-wrapper.menu-layout-horizontal .layout-main {
    padding-top: 110px;
    margin-left: 0px;
  }
}

.flex-center-x {
  display: flex;
  justify-content: center;
}

.flex-center-x-y {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

.flex-right-x {
  display: flex;
  justify-content: flex-end;
}


.pricing-div {
  height: 35px;
  align-self: stretch;
  margin: 2px 0;
  padding: 0 3px;
}

.pricing-div input{
  text-align: right;
}

.pricing-div .right{
  text-align: right;
}

.pricing-div .left{
  font-weight: bold;
  text-align: left;
}

.pricing-div .action {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;

}

.pricing-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.in-inventory {
  background-color: #afecbd !important;
}

.out-inventory {
  background-color: #ecabab !important;
}

.pac-container {
  z-index: 4000 !important;
}


.custom-badge {
  border-radius: 2px;
  padding: .25em .5em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-success {
    background-color: #C8E6C9;
    color: #256029;
  }

  &.status-danger {
    background-color: #FFCDD2;
    color: #C63737;
  }

  &.status-warning {
    background-color: #FEEDAF;
    color: #8A5340;
  }

  &.status-info {
    background-color: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
  }
}


/*[ TEXT ]
///////////////////////////////////////////////////////////
*/
/* ------------------------------------ */
.text-white {color: white;}
.text-black {color: black;}

.text-hov-white:hover {color: white;}

/* ------------------------------------ */
.text-up {text-transform: uppercase;}

/* ------------------------------------ */
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-middle {vertical-align: middle;}

/* ------------------------------------ */


.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #6c757d;
}
.p-selectbutton .p-button.p-highlight {
  background: #5E81AC;
  border-color: #5E81AC;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #4a7fbf;
  border-color: #4a7fbf;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: #f44336;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #f44336;
}


.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-inputgroup .p-inputwrapper>.p-component {
  flex: 1 1 auto;
  width: 100%;
}

.txtOverflow{
  position:relative;
  text-align:justify;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-warning {
  color: #ba7632;
}

.color-success {
  color: #167a1c;
}

// RESPONSIVE

@media (max-width: 575px) {
    .layout-content {
        padding: 0;
    }
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}


.p-scrollpanel {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.customScrollBar {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color .2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}



// para las mesas
.custom-select-button {
  font-size: 1.5rem;  
}

.custom-select-button .p-button {
  font-size: 1.5rem; 
  padding: 1rem; 
}

.text-overflow{
  width: 90px;
}

#numeroComensales.p-inputtext{
  text-align: center;
}